import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// Import your components
import HomePage from './HomePage';
import CancelPage from './CancelPage'
import NotFoundPage from './NotFoundPage'; // Create this component

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
         <Route path="/cancel/:BookingCode" element={<CancelPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
};

export default App;