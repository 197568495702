import React, { useEffect, useRef } from 'react';
import { Typography, Box } from '@mui/material';

const TermsAndConditions = () => {
    const myRef = useRef(null);


    useEffect(() => {
        setTimeout(() => {
            myRef.current.scrollIntoView({ behavior: "smooth" });
        }, 500);
    }, [])

    return (
        <Box sx={{ padding: 2, maxHeight: 400, overflow: 'scroll' }}>
            <Typography variant="h4" gutterBottom>
                Terms and Conditions
            </Typography>

            <Typography  ref={myRef} variant="h6" gutterBottom>
                1. Introduction
            </Typography>
            <Typography paragraph>
                Welcome to <strong>TripGo Luxembourg</strong>. By using our website and services, you agree to comply with and
                be bound by the following terms and conditions. If you do not agree with these terms, please refrain from
                using our services.
            </Typography>

            <Typography variant="h6" gutterBottom>
                2. Definitions
            </Typography>
            <Typography paragraph>
                - <strong>"Company"</strong> refers to <strong>TripGo Luxembourg</strong>, the operator of this website and
                platform. <br />
                - <strong>"Customer"</strong> refers to the individual or entity booking taxi services through our platform. <br />
                - <strong>"Driver"</strong> refers to the professional taxi operator providing the transport services.
            </Typography>

            <Typography variant="h6" gutterBottom >
                3. Service Description
            </Typography>
            <Typography paragraph>
                <strong>TripGo Luxembourg</strong> provides a platform to facilitate the booking of taxi services within
                Luxembourg. The actual transport services are rendered by licensed and registered taxi drivers who are
                independent contractors.
            </Typography>

            <Typography variant="h6" gutterBottom>
                4. Booking Policy
            </Typography>
            <Typography paragraph>
                1. Bookings must be made through our website or approved communication channels. <br />
                2. Customers are responsible for providing accurate information, including: <br />
                - Pick-up and drop-off locations <br />
                - Date and time <br />
                - Contact details <br />
                3. Any modifications or cancellations must be made at least <strong>42 hours</strong> in
                advance to avoid penalties or charges. <br />
                4. Failure to appear at the designated pick-up point without prior notice may result in a cancellation fee.
            </Typography>

            <Typography variant="h6" gutterBottom>
                5. Payment Terms
            </Typography>
            <Typography paragraph>
                1. Payments can be made online via our secure payment gateway or directly to the driver, where applicable. <br />
                2. All prices are quoted in <strong>Euros (€)</strong> and include applicable taxes unless otherwise stated. <br />
                3. Additional charges may apply for: <br />
                - Extra waiting time beyond the agreed pick-up time. <br />
                - Changes to the route or additional stops during the trip.
            </Typography>

            <Typography variant="h6" gutterBottom>
                6. Cancellation and Refund Policy
            </Typography>
            <Typography paragraph>
                1. Customers may cancel bookings free of charge up to <strong>24 hours</strong> before the
                scheduled pick-up time. <br />
                2. Cancellations made within <strong>24 hours</strong> of the pick-up time or no-shows may
                incur a fee of <strong>10 €</strong>. <br />
                3. Refunds for eligible cancellations will be processed within{' '}
                <strong>5-7 business days</strong>.
            </Typography>

            <Typography variant="h6" gutterBottom>
                7. Customer Responsibilities
            </Typography>
            <Typography paragraph>
                1. Customers must ensure that they are present at the pick-up location at the scheduled time. <br />
                2. Customers are responsible for the conduct of all passengers during the trip. <br />
                3. Any damages caused to the vehicle by the customer or passengers may result in additional charges.
            </Typography>

            <Typography variant="h6" gutterBottom>
                8. Driver Conduct and Responsibility
            </Typography>
            <Typography paragraph>
                1. All drivers partnered with <strong>TripGo Luxembourg</strong> are licensed, insured, and trained to provide
                professional taxi services. <br />
                2. Drivers reserve the right to refuse service if customers exhibit abusive, intoxicated, or unsafe behavior.
            </Typography>

            <Typography variant="h6" gutterBottom>
                9. Limitation of Liability
            </Typography>
            <Typography paragraph>
                1. <strong>TripGo Luxembourg</strong> acts as a booking platform and is not liable for delays, cancellations, or
                other issues arising directly from the driver’s service. <br />
                2. The company is not responsible for lost or damaged items left in the vehicle. Please contact customer support
                for assistance in retrieving lost items.
            </Typography>

            <Typography variant="h6" gutterBottom>
                10. Privacy Policy
            </Typography>
            <Typography paragraph>
                All personal information provided by customers is processed in compliance with GDPR and Luxembourg’s data
                protection laws. For more details, please refer to our{' '}
                <a href="#privacy-policy" style={{ textDecoration: 'underline' }}>
                    Privacy Policy
                </a>
                .
            </Typography>

            <Typography variant="h6" gutterBottom>
                11. Governing Law
            </Typography>
            <Typography paragraph>
                These terms and conditions are governed by the laws of Luxembourg. Any disputes arising from the use of our
                services shall be resolved in Luxembourg courts.
            </Typography>

            <Typography variant="h6" gutterBottom>
                12. Changes to Terms and Conditions
            </Typography>
            <Typography paragraph>
                <strong>TripGo Luxembourg</strong> reserves the right to update or modify these terms and conditions at any
                time. Customers will be notified of any significant changes through our website or email.
            </Typography>

            <Typography variant="h6" gutterBottom>
                13. Contact Information
            </Typography>
            <Typography paragraph>
                For any questions or concerns, please contact us at: <br />
                - <strong>Email:</strong> Hello@tripgo.lu <br />
                - <strong>Phone:</strong> +352-661392929 <br />
                {/* - <strong>Address:</strong> [Insert Office Address] */}
            </Typography>
        </Box>
    );
};

export default TermsAndConditions;
