import React from 'react';
import { Container, Box, Typography, Link, IconButton, Fab } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Footer from './components/Footer';
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';

function NotFoundPage() {
    const navigate = useNavigate();

    return (
        <ThemeProvider theme={theme}>
            <Box
                display="flex"
                flexDirection="column"
                minHeight="100vh"
                sx={{
                    // backgroundImage: `url(${backgroundImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    color: '#FFFFFF',
                    backgroundColor: '#e7e8f3'
                }}
            >
                <Box
                    sx={{
                        position: "fixed",
                        display: { xs: 'block', md: 'none' },
                        bottom: "10px", // Adjust as needed
                        left: "10px", // Adjust as needed
                        zIndex: 1000, // Ensure it's on top of other elements
                    }}
                >
                    <Fab
                        color="success"
                        href="https://wa.me/352661392929" // Replace with your WhatsApp link
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{
                            backgroundColor: "#25D366",
                            "&:hover": {
                                backgroundColor: "#1EBE54",
                            },
                        }}
                    >
                        <WhatsAppIcon />
                    </Fab>
                </Box>

                {/* Header */}
                <Box sx={{ display: 'flex', justifyContent: 'space-around', p: 1, m: 1 }}>
                    <Typography
                        component="img"
                        src={`${process.env.PUBLIC_URL}/logo.png`}
                        alt="Company Logo"
                        sx={{
                            height: 60,
                            width: 'auto',
                            objectFit: 'contain',
                            maxWidth: '150px'
                        }}
                    />
                    <Link href="https://wa.me/352661392929" target="_blank" underline="none" sx={{ backgroundColor: 'rgba(255, 255, 255, 0.8)', borderRadius: 5, display: { xs: 'none', md: 'block' }, }}>
                        <IconButton sx={{ color: 'green' }}>
                            <WhatsAppIcon />
                            <Typography variant="body1" sx={{ textAlign: 'center', padding: '10px', fontWeight: 'bold' }}>
                                +352 661 39 29 29
                            </Typography>
                        </IconButton>
                    </Link>
                </Box>

                {/* Main Content */}
                <Container component="main" sx={{ flexGrow: 1, my: 4, display: 'flex', overflow: 'hidden', width: '100%', flexDirection: 'column', alignItems: 'center' }}>
                    <img
                        src={`${process.env.PUBLIC_URL}/notfound.jpg`}
                        alt="404 Not Found"
                        style={styles.image}
                    />
                    <button style={styles.button} onClick={() => navigate('/')}>
                        Go Back Home
                    </button>
                </Container>
                <Footer />
            </Box>
        </ThemeProvider>
    );
}

const styles = {

    image: {
        maxWidth: '100%',
        maxHeight: '420px',
        marginBottom: '20px',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
        borderRadius: '10px'
    },

    button: {
        padding: '10px 20px',
        fontSize: '16px',
        color: '#fff',
        backgroundColor: '#5b6df7',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        transition: 'background-color 0.3s',
    },

};

export default NotFoundPage;


