import React, { useState, useEffect } from 'react';
import { Box, Typography, Alert, useMediaQuery, TextField } from '@mui/material';
import dayjs from 'dayjs';
import AddressAutocomplete from './AddressAutocomplete';
import { DatePicker, TimePicker } from 'antd';
import { Spin } from "antd";
import { TimePicker as MuiTimePicker, DatePicker as MuiDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CarCard from '../Shared/CarCard';

import { calcCost, fetchDisabledDates, antdDisabledDate, muiDisabledDates, isInLuxembourg } from '../../helper';
function TripInfoAddressForm({ tripData, setTripData, setError, setIsloading }) {
    const isMobile = useMediaQuery('(max-width:600px)');
    const [pickupAddress, setPickupAddress] = useState('');
    const [dropoffAddress, setDropoffAddress] = useState('');
    const [inLuxembourg, setInLuxembourg] = useState(true);

    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTime, setSelectedTime] = useState(null);

    const [distance, setDistance] = useState(null);
    const [price, setPrice] = useState(null);
    const [loading, setLoading] = useState(false);

    const [disabledDates, setDisabledDates] = useState([]);


    useEffect(()=>{
        const validateAddresses = async () => {
            if (pickupAddress && dropoffAddress) {
                try {
                    const isPickupInLuxembourg = await isInLuxembourg(pickupAddress);
                    const isDropoffInLuxembourg = await isInLuxembourg(dropoffAddress);
                    // Perform actions based on the validation
                    if (!isPickupInLuxembourg && !isDropoffInLuxembourg) {
                        setInLuxembourg(false)
                    }
                    else
                        setInLuxembourg(true)
                } catch (error) {
                    console.error("Error validating addresses:", error);
                }
            }
        };
        validateAddresses();
    },[pickupAddress, dropoffAddress])

    useEffect(() => {
        fetchDisabledDates().then(dates => setDisabledDates(dates));
    }, []);

    const calculateDistanceAndPrice = async () => {

        if (!pickupAddress || !dropoffAddress) return;
        setLoading(true); // Start loading spinner
        try {
            setIsloading(true)
            const response = await fetch(`${process.env.REACT_APP_SOS_ERROR}distance-matrix`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    pickupAddress,
                    dropoffAddress,
                }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();

            if (data.rows[0].elements[0].status === 'OK') {
                const distanceValue = data.rows[0].elements[0].distance.value / 1000; // in kilometers
                const distanceText = data.rows[0].elements[0].distance.text;

                setDistance(distanceValue);

                setPrice(calcCost(tripData.date, tripData.time, distanceValue).totalCost); // Rounded to 2 decimal places
                setTripData((prevTripData) => ({
                    ...prevTripData,
                    distance: distanceText,
                    details: calcCost(tripData.date, tripData.time, parseFloat(distanceText.split(' ')[0])).details,
                    price: calcCost(tripData.date, tripData.time, parseFloat(distanceText.split(' ')[0])).totalCost,
                }));

            } else {

                setDistance(null);
                setPrice(null);
                alert('Distance information not available for these locations.');
            }
        } catch (error) {

            console.error('Error fetching distance:', error);
            alert('An error occurred while calculating the distance.');
        } finally {
            setIsloading(false)
            setLoading(false); // Stop loading spinner
        }
    };

    useEffect(() => {
        if (pickupAddress && dropoffAddress && selectedDate && selectedTime) {
            calculateDistanceAndPrice();
            setError('')
        }
    }, [pickupAddress, dropoffAddress, selectedDate, selectedTime]);

    const minDate = dayjs().add(48, 'hour');
    return (
        <Box>
            <AddressAutocomplete
                label="Pickup Address"
                placeholder="Pickup address"
                value={tripData.pickup}
                onAddressSelect={(address) => { setTripData({ ...tripData, price: 123 }); setPickupAddress(address); setTripData({ ...tripData, pickup: address }) }}
            />
            <AddressAutocomplete
                label="Drop off Address"
                placeholder="Drop-off address"
                value={tripData.dropoff}
                onAddressSelect={(address) => { setDropoffAddress(address); setTripData({ ...tripData, dropoff: address }) }}
            />

            <Box sx={{ display: 'flex', flexDirection: "row", }}>
                <Box sx={{ width: '100%' }}>
                    <Box spacing={1} sx={{ mt: 0 }}>

                        <Box sx={{ display: 'flex', flexDirection: { xs: 'row' }, justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                            <Box sx={{ width: { xs: '100%', md: '49%' }, justifyContent: 'center', alignItems: 'center', display: 'flex' }} >
                                {isMobile ? (
                                    // MUI TimePicker for mobile view
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <MuiDatePicker
                                            maxDate={dayjs().add(90, 'days', 'YYYY-MM-DD')}
                                            shouldDisableDate={(date) => muiDisabledDates(date, disabledDates)}
                                            label="Select Date"
                                            value={selectedDate}
                                            onChange={(newDate) => { setSelectedDate(newDate); setTripData({ ...tripData, date: dayjs(newDate).format('YYYY-MM-DD') }) }}
                                            minDate={minDate}
                                            renderInput={(params) => <TextField {...params} fullWidth />}
                                            slotProps={{
                                                textField: {
                                                    sx: {
                                                        '& .MuiInputLabel-root': {
                                                            color: '#aaa',
                                                        },
                                                    },
                                                },
                                            }}
                                        />

                                    </LocalizationProvider>
                                ) : (
                                    <DatePicker
                                        maxDate={dayjs().add(90, 'days', 'YYYY-MM-DD')}
                                        placement={"topRight"}
                                        onFocus={(e) => e.target.blur()} // Prevent focus zoom
                                        style={antStyle}
                                        format="YYYY-MM-DD"
                                        disabledDate={(current) => antdDisabledDate(current, disabledDates)}
                                        onChange={(date) => {
                                            setSelectedDate(dayjs(date).format('YYYY-MM-DD'));
                                            setTripData({ ...tripData, date: date ? dayjs(date).format('YYYY-MM-DD') : null });
                                        }}
                                    />
                                )}
                            </Box>
                            <Box sx={{ width: { xs: '100%', md: '49%' }, justifyContent: 'center', alignItems: 'center', display: 'flex', }}>
                                {isMobile ? (
                                    // MUI TimePicker for mobile view
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>

                                        <MuiTimePicker
                                            onAccept={(newTime) => {
                                                setSelectedTime(newTime);
                                                setTripData({ ...tripData, time: dayjs(newTime).format('HH:mm') });
                                            }}
                                            label="Select Time"
                                            sx={{ height: 1 }}
                                            value={selectedTime}
                                            slotProps={{
                                                textField: {
                                                    sx: {
                                                        '& .MuiInputLabel-root': {
                                                            color: '#aaa',
                                                        },
                                                    },
                                                },
                                            }}
                                            renderInput={(params) => <TextField {...params} style={antStyle} placeholder="Select time"
                                            />}
                                            ampm={false}
                                            minutesStep={5}
                                        />
                                    </LocalizationProvider>
                                ) : (
                                    <TimePicker
                                        value={selectedTime}
                                        format={'HH:mm'}
                                        size="large"
                                        minuteStep={5}
                                        style={antStyle}
                                        needConfirm={true}
                                        showNow={false}
                                        onChange={(newTime) => { setSelectedTime(newTime); setTripData({ ...tripData, time: dayjs(newTime).format('HH:mm') }) }}
                                        placeholder='Select time'
                                        placement={'bottomRight'}
                                    />
                                )}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            {distance != null && (distance < 10 ||  distance > 300) && <Alert variant="filled" severity="info" sx={{ my: 2 }}>
                Unfortunately, we are unable to accommodate short trips of less than 10 km or more than 300 km at this time.
                <Typography></Typography>
                We are working diligently to expand our services to cover all distances soon.
                <Typography></Typography>
                Thank you for your understanding, and we look forward to serving you in the near future.
            </Alert>}

            {!inLuxembourg && <Alert variant="outlined" severity="warning" sx={{ my: 2 }}>
            Unfortunately, we currently do not support trips outside Luxembourg.
                <Typography></Typography>
                Our services are available for trips where at least one of the pickup or drop-off addresses is located within Luxembourg. We appreciate your understanding!
            </Alert>}

            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', pb: 2, pt: 4, }}>
                {loading && <Spin size="large" />}
                {distance && price && (distance > 10 && distance < 300) && !loading && inLuxembourg && (
                    <Box sx={{ width: '100%', mt: 0 }} display="flex" flexDirection={'column'} alignItems="center" justifyContent="center">
                        <CarCard price={price} details={tripData.details} />
                    </Box>
                )}
            </Box>
        </Box>
    );
}

const antStyle = {

    width: '100%', height: 56, backgroundColor: 'rgba(255, 255, 255, 0.1)', borderRadius: '4px', // Matches MUI's border radius
    border: '1px solid #bbb',
}

export default TripInfoAddressForm;
