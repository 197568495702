
// helper.js
import axios from 'axios';
import dayjs, { Dayjs } from 'dayjs';
export function calcCost(date, time, distance) {
    // Define constants for pricing
    const PICKUP_CHARGE = 2;
    const PRICE_PER_KM_10_TO_75 = 2.05;
    const PRICE_PER_KM_75_TO_300 = 1.5;
    const PRICE_PER_KM_300_TO_400 = 1.3;
    const NIGHT_SERVICE_SURCHARGE = 0.1; // 10%
    const SUNDAY_SURCHARGE = 0.2; // 20%

    // Parse distance
    const dist = parseFloat(distance);

    // Calculate base cost based on distance
    let baseCost = 0;
    let distanceDetails = "";
    if (dist <= 75) {
        baseCost = dist * PRICE_PER_KM_10_TO_75;
        distanceDetails = `Distance: ${dist.toFixed(2)} x $${PRICE_PER_KM_10_TO_75.toFixed(2)}`;
    } else if (dist <= 300) {
        baseCost = 75 * PRICE_PER_KM_10_TO_75 + (dist - 75) * PRICE_PER_KM_75_TO_300;
        distanceDetails = `Distance: 75 x $${PRICE_PER_KM_10_TO_75.toFixed(2)}, ${(dist - 75).toFixed(2)} x $${PRICE_PER_KM_75_TO_300.toFixed(2)}`;
    } else if (dist <= 400) {
        baseCost = 75 * PRICE_PER_KM_10_TO_75 + (300 - 75) * PRICE_PER_KM_75_TO_300 + (dist - 300) * PRICE_PER_KM_300_TO_400;
        distanceDetails = `Distance: 75 x $${PRICE_PER_KM_10_TO_75.toFixed(2)}, ${(300 - 75).toFixed(2)} x $${PRICE_PER_KM_75_TO_300.toFixed(2)}, ${(dist - 300).toFixed(2)} x $${PRICE_PER_KM_300_TO_400.toFixed(2)}`;
    } else {
        baseCost = 75 * PRICE_PER_KM_10_TO_75 + (300 - 75) * PRICE_PER_KM_75_TO_300 + (400 - 300) * PRICE_PER_KM_300_TO_400;
        distanceDetails = `Distance: 75 x $${PRICE_PER_KM_10_TO_75.toFixed(2)}, ${(300 - 75).toFixed(2)} x $${PRICE_PER_KM_75_TO_300.toFixed(2)}, ${(400 - 300).toFixed(2)} x $${PRICE_PER_KM_300_TO_400.toFixed(2)}`;
    }

    // Add pickup charge
    let totalCost = baseCost + PICKUP_CHARGE;
    let costDetails = `Pickup: $${PICKUP_CHARGE.toFixed(2)}, ${distanceDetails}`;

    // Check for night service (22:00 - 06:00)
    const [hours, minutes] = time.split(':').map(Number);
    if (hours >= 22 || hours < 6) {
        const nightCharge = totalCost * NIGHT_SERVICE_SURCHARGE;
        totalCost += nightCharge;
        costDetails += `, Night service: $${nightCharge.toFixed(2)}`;
    }

    // Check for Sunday
    const tripDate = new Date(date);
    const dayOfWeek = tripDate.getDay(); // Sunday = 0

    if (dayOfWeek === 0) {
        const sundayCharge = totalCost * SUNDAY_SURCHARGE;
        totalCost += sundayCharge;
        costDetails += `, Sunday surcharge: $${sundayCharge.toFixed(2)}`;
    }

    return { totalCost: totalCost.toFixed(2), details: costDetails };
}

export async function fetchDisabledDates() {
    try {
        const response = await axios.get(`${process.env.REACT_APP_SOS_ERROR}bookings/future-booking-dates`);

        const data = response.data;

        if (data.success) {
            const serverDisabledDates = data.disabledDates.map(date => dayjs(date));

            // Add today and tomorrow
            const today = dayjs();
            const tomorrow = today.add(1, 'day');

            // Combine server dates with today and tomorrow
            const combinedDates = [...serverDisabledDates, today, tomorrow];

            // Save combined disabled dates
            return (combinedDates);
        }
    } catch (error) {
        // console.error('Error fetching disabled dates:', error);
    }
};

export function antdDisabledDate(current, disabledDates) {
    // Disable all dates before today and any in the disabledDates array
    return current && (current.isBefore(dayjs(), 'day') || disabledDates.some(date => current.isSame(date, 'day')));
};

export function muiDisabledDates(date, disabledDates) {
    if (!date) return false; // Handle null dates
    return date.isBefore(dayjs(), 'day') || disabledDates.some(disabledDate => date.isSame(disabledDate, 'day'));
};

export async function  isInLuxembourg(address)  {
    const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;
    try {
      const response = await fetch(geocodeUrl);
      if (!response.ok) {
        throw new Error(`Geocoding API error: ${response.status}`);
      }
      const data = await response.json();
      const results = data.results;
      if (results.length > 0) {
        const country = results[0].address_components.find(component =>
          component.types.includes('country')
        );
        return country && country.short_name === 'LU'; // Check if country is Luxembourg
      }
      return false; // No results from Geocoding API
    } catch (error) {
      console.error('Error validating address:', error);
      return false;
    }
  };


