import { createTheme } from '@mui/material/styles';
// import '@fontsource/open-sans';

const theme = createTheme({
    typography: {
        // fontFamily: 'Open Sans, sans-serif',
        fontFamily: "Montserrat, Arial, sans-serif",
    },
});

export default theme;
